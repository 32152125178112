export enum TableMode {
  Ordering ="Ordering",
  Preview = "Preview"
}
export interface TableBlocks {
  [tableBlockId: string]: TableBlock;
}

export interface TableBlock {
  areas1?: string[];
  areas2?: string[];
  blockReserve?: string[];
  start: number;
  end: number;
  widgets: {
    [widgetId: string]: boolean;
  };
  fixedTables?: string[];
  sectionTables?: string[];
  sections?: string[];
  enabled?: boolean;
}

export type TableOptionMappings = {
  [widgetId: string]: {
    [table: string]: TableOption
  }
}

export type TableOption = {
  mode: TableMode 
}